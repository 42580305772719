export default {
    name: 'customGeneral',
    before: [],
    after: [],
    setup: (app) => {

        const support = app.general.getItem('support');

        app.general.changeItem('support', {
            ...support,
            formUrl: 'https://forms.clickup.com/2262661/f/251m5-11674/1NJCS51KXLTYU5KVIZ',
        });
    },
};