import { Redirect } from 'react-router-dom';

import { DASHBOARD_ROUTE } from '../setup/modules/dashboard/consts';

export const withDetailsRenderWrapper =
    () =>
    (Component, allowedIdRegex = /^(?!add$|edit$|form$).+$/) => {
        function WrappedComponent(props) {
            const { params = {} } = props;
            const { id } = params;

            if (!allowedIdRegex.test(id)) {
                return <Redirect to={DASHBOARD_ROUTE} />;
            }
            return <Component {...props} />;
        }

        return WrappedComponent;
    };
