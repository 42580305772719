import createConfigurableObject from '../../../utils/configurationObject';
import { SUPPORT_EVENT_TRIGGER } from '../../../components/SupportModal/SupportModal';
export default (app) => {
    app.headerMenuItems = app.headerMenuItems || createConfigurableObject();

    const openSupportModalEvent = new CustomEvent(SUPPORT_EVENT_TRIGGER);

    app.headerMenuItems.addMany({
        support: {
            label: 'Support',
            icon: 'QuestionCircle',
            onClick: () => window.dispatchEvent(openSupportModalEvent),
            order: 90,
        },
        logout: {
            hasBorder: true,
            label: 'Logout',
            icon: 'ArrowRightCircle',
            link: '/logout',
            order: 100,
        },
    });
};
