import createConfigurableObject from '../../../utils/configurationObject';

export default (app) => {
    app.general = app.general || createConfigurableObject();
    app.general.addItem('name', 'Skunexus');

    app.general.addItem('modules', {
        fulfillment: {
            mainTabsParamsConfig: {},
            rolesForDefaultAssignedUser: ['Picker / Packer'],
        },
        fulfillmentShipGroupPicking: {
            forceTopDownPicking: true,
        },
    });

    app.general.addItem('support', {
        formUrl: 'https://forms.clickup.com/2262661/f/251m5-9974/0FVE4WQGEPUMP89PBY',
    });
};
