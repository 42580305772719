import {
    WAREHOUSE_TITLE_PREFIX,
    WAREHOUSES_GRID_ROUTE,
    WAREHOUSES_DETAILS_PATH,
    WAREHOUSES_DETAILS_ROUTE,
    WAREHOUSES_FORM_PATH,
    WAREHOUSES_FORM_ROUTE,
    WAREHOUSES_DT_FORM_PATH,
    WAREHOUSES_DT_FORM_ROUTE,
    WAREHOUSES_LOCATION_GRID_PATH,
    WAREHOUSES_LOCATION_GRID_ROUTE,
    WAREHOUSES_LOCATION_WITH_PRODUCTS_GRID_PATH,
    WAREHOUSES_LOCATION_WITH_PRODUCTS_GRID_ROUTE,
    DESCRIPTOR_TITLE_PREFIX,
    DESCRIPTORS_GRID_ROUTE,
    DESCRIPTORS_FORM_PATH,
    DESCRIPTORS_FORM_ROUTE,
    DESCRIPTION_TEMPLATE_TITLE_PREFIX,
    DESCRIPTION_TEMPLATES_GRID_ROUTE,
    DESCRIPTION_TEMPLATES_FORM_PATH,
    DESCRIPTION_TEMPLATES_FORM_ROUTE,
    WAREHOUSE_CONFIGURATION_TITLE_PREFIX,
    WAREHOUSE_CONFIGURATION_GRID_ROUTE,
    WAREHOUSE_CONFIGURATION_FORM_PATH,
    WAREHOUSE_CONFIGURATION_FORM_ROUTE,
    CARTS_GRID_ROUTE,
    CART_TITLE_PREFIX,
    CARTS_TOTES_GRID_PATH,
    CARTS_TOTES_GRID_ROUTE,
    CARTS_FORM_PATH,
    CARTS_FORM_ROUTE,
    TOTE_TITLE_PREFIX,
    TOTES_GRID_ROUTE,
    TOTES_FORM_PATH,
    TOTES_FORM_ROUTE,
} from './consts';

import { EMPTY_TITLE_CRUMB } from '../constants';
import withSettingsTabsWrapper from '../settings/components/withSettingsTabsWrapper';
import { withDetailsRenderWrapper } from '../../../HOCs/DetailsRenderWrapper';
import { withFormRenderWrapper } from '../../../HOCs/FormRenderWrapper';
import { hasAnyPermissions } from '../../../utils/helpers';

export default (app) => ({
    warehouses: {
        path: app.settingsWarehousesTabs.getItem('warehouses').link,
        title: app.settingsWarehousesTabs.getItem('warehouses').title,
        permission: app.settingsWarehousesTabs.getItem('warehouses').permission,
        breadcrumbConfig: {
            title: `${app.settingsWarehousesTabs.getItem('warehouses').label}`,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: WAREHOUSES_FORM_ROUTE,
            detailsPageRoute: WAREHOUSES_DETAILS_ROUTE,
            locationsPageRoute: WAREHOUSES_LOCATION_GRID_ROUTE,
            locationsProductsPageRoute: WAREHOUSES_LOCATION_WITH_PRODUCTS_GRID_ROUTE,
            permissions: {
                canAdd: hasAnyPermissions('warehouses.create'),
                withExport: true,
                canEditCustomAttributes: hasAnyPermissions('warehouses - custom attributes.value edit'),
                canActivate: hasAnyPermissions('warehouses.update'),
                canDeactivate: hasAnyPermissions('warehouses.update'),
                canEdit: hasAnyPermissions('warehouses.update'),
                canView: hasAnyPermissions('warehouses.show'),
                canDelete: hasAnyPermissions('warehouses.delete'),
                canViewLocations: true,
                canViewProductsByLocation: true,
                withWarehouseLink: hasAnyPermissions('warehouses.show'),
            }
        }),
        loader: () => import('@skunexus/warehouses-grid'),
        render: withSettingsTabsWrapper(app),
    },
    warehouseForm: {
        path: WAREHOUSES_FORM_PATH,
        title: `${WAREHOUSE_TITLE_PREFIX} Form`,
        breadcrumbConfig: { title: 'Add Warehouse', isReplaceble: true },
        permission: ['warehouses.create', 'warehouses.update'],
        props: () => ({
            indexPageRoute: WAREHOUSES_GRID_ROUTE,
            detailsPageRoute: WAREHOUSES_DT_FORM_ROUTE,
            permissions: {
                canAdd: hasAnyPermissions('warehouses.create'),
                canEdit: hasAnyPermissions('warehouses.update'),
                canEditCustomValues: hasAnyPermissions(
                    'warehouses - custom attributes.value edit',
                ),
            }
            
        }),
        loader: () => import('@skunexus/warehouse-form'),
        render: withFormRenderWrapper(app)
    },
    warehouseDetails: {
        path: WAREHOUSES_DETAILS_PATH,
        title: `${WAREHOUSE_TITLE_PREFIX} Details`,
        permission: ['warehouses.show'],
        breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
        props: () => ({
            gridPageRoute: WAREHOUSES_GRID_ROUTE,
            updateFormPageRoute: WAREHOUSES_FORM_ROUTE,
            locationsPageRoute: WAREHOUSES_DETAILS_ROUTE,
            permissions: {
                canEdit: hasAnyPermissions('warehouses.update'),
                canViewLocations: true,
            }
        }),
        loader: () => import('@skunexus/warehouse-details'),
        render: withDetailsRenderWrapper(app),
    },
    warehouseLocations: {
        path: WAREHOUSES_LOCATION_GRID_PATH,
        title: `${WAREHOUSE_TITLE_PREFIX} > Locations`,
        permission: ['warehouses.index'],
        breadcrumbConfig: { title: `Location`, isReplaceble: true },
        props: () => ({
            permissions: {
                canAdd: true,
                withImportStock: true,
                canPrintAll: true,
                withExport: true,
                canActivate: true,
                canDeactivate: true,
                canPrint: true,
                canDelete: true
            }
        }),
        loader: () => import('@skunexus/warehouse-locations'),
    },
    warehouseLocationsProducts: {
        path: WAREHOUSES_LOCATION_WITH_PRODUCTS_GRID_PATH,
        title: `${WAREHOUSE_TITLE_PREFIX} > Products by Location`,
        permission: ['warehouses.index'],
        breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
        props: () => ({
            productDetailsStockPageRoute: '/products/stock',
            permissions: {
                withExport: true,
                canActivate: true,
                canDeactivate: true,
                canPrint: true,
                canCycleCount: true,
                canAdjust: true,
                canAddStockReservation: true,
                withProductStockLink: hasAnyPermissions('products.show')
            }
        }),
        loader: () => import('@skunexus/warehouse-locations-products'),
    },
    warehouseDtForm: {
        path: WAREHOUSES_DT_FORM_PATH,
        title: `${WAREHOUSE_TITLE_PREFIX} Descriptor Templates`,
        breadcrumbConfig: { title: 'Add Descriptor Template', isReplaceble: true },
        permission: ['warehouses.create', 'warehouses.update'],
        props: () => ({
            indexPageRoute: WAREHOUSES_GRID_ROUTE,
            permissions: {
                canAdd: hasAnyPermissions('warehouses.create'),
                canEdit: hasAnyPermissions('warehouses.update'),
            }
        }),
        loader: () => import('@skunexus/warehouse-dt-form'),
        render: withFormRenderWrapper(app)
    },
    descriptors: {
        path: app.settingsWarehousesTabs.getItem('descriptors').link,
        title: app.settingsWarehousesTabs.getItem('descriptors').title,
        permission: app.settingsWarehousesTabs.getItem('descriptors').permission,
        breadcrumbConfig: {
            title: `${app.settingsWarehousesTabs.getItem('descriptors').label}`,
            isReplaceble: true,
            isTab: true,
        },
        props: () => ({
            formPageRoute: DESCRIPTORS_FORM_ROUTE,
            permissions: {
                canAdd: hasAnyPermissions('settings - descriptors.create'),
                withExport: true,
                canEdit: hasAnyPermissions('settings - descriptors.update'),
                canDelete: hasAnyPermissions('settings - descriptors.delete'),
            }
        }),
        loader: () => import('@skunexus/descriptors-grid'),
        render: withSettingsTabsWrapper(app),
    },
    descriptorsForm: {
        path: DESCRIPTORS_FORM_PATH,
        title: `${DESCRIPTOR_TITLE_PREFIX} Form`,
        breadcrumbConfig: { title: 'Add Descriptor', isReplaceble: true },
        permission: ['settings - descriptors.create', 'settings - descriptors.update'],
        props: () => ({
            indexPageRoute: DESCRIPTORS_GRID_ROUTE,
            permissions: {
                canAdd: hasAnyPermissions('settings - descriptors.create'), 
                canEdit: hasAnyPermissions('settings - descriptors.update')
            }
        }),
        loader: () => import('@skunexus/descriptors-form'),
        render: withFormRenderWrapper(app)
    },
    descriptorTemplates: {
        path: app.settingsWarehousesTabs.getItem('descriptorTemplates').link,
        title: app.settingsWarehousesTabs.getItem('descriptorTemplates').title,
        permission: app.settingsWarehousesTabs.getItem('descriptorTemplates').permission,
        breadcrumbConfig: {
            title: `${app.settingsWarehousesTabs.getItem('descriptorTemplates').label}`,
            isReplaceble: true,
            isTab: true,
        },
        props: () => ({
            formPageRoute: DESCRIPTION_TEMPLATES_FORM_ROUTE,
            permissions: {
                canAdd: hasAnyPermissions('settings - description templates.create'),
                withExport: true,
                canEdit: hasAnyPermissions('settings - description templates.update'),
                canDelete: hasAnyPermissions('settings - description templates.delete'),
                withDtFormLink: hasAnyPermissions('settings - description templates.update'),
            }
        }),
        loader: () => import('@skunexus/description-templates-grid'),
        render: withSettingsTabsWrapper(app),
    },
    descriptorTemplatesForm: {
        path: DESCRIPTION_TEMPLATES_FORM_PATH,
        title: `${DESCRIPTION_TEMPLATE_TITLE_PREFIX} Form`,
        breadcrumbConfig: { title: 'Add Descriptor Template', isReplaceble: true },
        permission: ['settings - description templates.create', 'settings - description templates.update'],
        props: () => ({
            indexPageRoute: DESCRIPTION_TEMPLATES_GRID_ROUTE,
            permissions: {
                canAdd: hasAnyPermissions('settings - description templates.create'),
                canEdit: hasAnyPermissions('settings - description templates.update'),
            }
        }),
        loader: () => import('@skunexus/description-templates-form'),
        render: withFormRenderWrapper(app)
    },
    warehouseConfigurations: {
        path: app.settingsWarehousesTabs.getItem('configuration').link,
        title: app.settingsWarehousesTabs.getItem('configuration').title,
        permission: app.settingsWarehousesTabs.getItem('configuration').permission,
        breadcrumbConfig: {
            title: `${app.settingsWarehousesTabs.getItem('configuration').label}`,
            isReplaceble: true,
            isTab: true,
        },
        props: () => ({
            formPageRoute: WAREHOUSE_CONFIGURATION_FORM_ROUTE,
            permissions: {
                canAdd: true,
                withExport: true,
                canEdit: true,
                canDelete: true,
                withWCFormLink: true,
            }
        }),
        loader: () => import('@skunexus/warehouses-configuration'),
        render: withSettingsTabsWrapper(app),
    },
    warehouseConfigurationForm: {
        path: WAREHOUSE_CONFIGURATION_FORM_PATH,
        title: `${WAREHOUSE_CONFIGURATION_TITLE_PREFIX} Form`,
        breadcrumbConfig: { title: 'Add Warehouse Configuration', isReplaceble: true },
        permission: ['settings - warehouses.manage descriptor values order'],
        props: () => ({
            indexPageRoute: WAREHOUSE_CONFIGURATION_GRID_ROUTE,
            permissions: {
                canAdd: hasAnyPermissions('settings - warehouses.manage descriptor values order'),
                canEdit: hasAnyPermissions('settings - warehouses.manage descriptor values order')
            }
        }),
        loader: () => import('@skunexus/warehouse-cf-form'),
        render: withFormRenderWrapper(app)
    },
    carts: {
        path: app.settingsWarehousesTabs.getItem('carts').link,
        title: app.settingsWarehousesTabs.getItem('carts').title,
        permission: app.settingsWarehousesTabs.getItem('carts').permission,
        breadcrumbConfig: {
            title: `${app.settingsWarehousesTabs.getItem('carts').label}`,
            isReplaceble: true,
            isTab: true,
        },
        props: () => ({
            formPageRoute: CARTS_FORM_ROUTE,
            totesGridPageRoute: CARTS_TOTES_GRID_ROUTE,
            permissions: {
                canAdd: hasAnyPermissions('carts.create'),
                withExport: true,
                canEdit: hasAnyPermissions('carts.update'),
                canDelete: hasAnyPermissions('carts.delete'),
                canPrint: true,
                canTotes: hasAnyPermissions('totes.index'),
            }
        }),
        loader: () => import('@skunexus/carts-grid'),
        render: withSettingsTabsWrapper(app),
    },
    cartTotes: {
        path: CARTS_TOTES_GRID_PATH,
        title: `${CART_TITLE_PREFIX} Totes`,
        permission: 'totes.index',
        breadcrumbConfig: { title: `Cart Totes`, isReplaceble: true },
        props: () => ({
            permissions: {
                withExport: true,
                canPrint: true,
            }
        }),
        loader: () => import('@skunexus/cart-totes-grid'),
    },
    cartsForm: {
        path: CARTS_FORM_PATH,
        title: `${CART_TITLE_PREFIX} Form`,
        permission: ['carts.create', 'carts.update'],
        breadcrumbConfig: { title: 'Add Cart', isReplaceble: true },
        props: () => ({
            indexPageRoute: CARTS_GRID_ROUTE,
            permissions: {
                canAdd: hasAnyPermissions('carts.create'),
                canEdit: hasAnyPermissions('carts.update'),
            }
        }),
        loader: () => import('@skunexus/carts-form'),
        render: withFormRenderWrapper(app)
    },
    totes: {
        path: app.settingsWarehousesTabs.getItem('totes').link,
        title: app.settingsWarehousesTabs.getItem('totes').title,
        permission: app.settingsWarehousesTabs.getItem('totes').permission,
        breadcrumbConfig: {
            title: `${app.settingsWarehousesTabs.getItem('totes').label}`,
            isReplaceble: true,
            isTab: true,
        },
        props: () => ({
            formPageRoute: TOTES_FORM_ROUTE,
            permissions: {
                canAdd: hasAnyPermissions('totes.create'),
                withExport: true,
                canActivate: hasAnyPermissions('totes.enable'),
                canDeactivate: hasAnyPermissions('totes.disable'),
                canDelete: hasAnyPermissions('totes.delete'),
                canPrint: true
            }
        }),
        loader: () => import('@skunexus/totes-grid'),
        render: withSettingsTabsWrapper(app),
    },
    toteForm: {
        path: TOTES_FORM_PATH,
        title: `${TOTE_TITLE_PREFIX} Form`,
        breadcrumbConfig: { title: 'Add Tote', isReplaceble: true },
        permission: ['totes.create'],
        props: () => ({
            indexPageRoute: TOTES_GRID_ROUTE,
        }),
        loader: () => import('@skunexus/tote-form'),
    },
});
