import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Modal } from '@skunexus/component-catalog';

import styles from './styles.module.css';

export const SUPPORT_EVENT_TRIGGER = 'openSupportModal';
const SUPPORT_URL_PARAM = 'support';

function SupportModal({ formUrl = '' }) {
    const [isOpen, setOpen] = useState(false);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const handleEvent = () => {
            const currentPath = window.location.pathname;
            history.push(`${currentPath}?${SUPPORT_URL_PARAM}`);
        };

        window.addEventListener(SUPPORT_EVENT_TRIGGER, handleEvent);

        return () => {
            window.removeEventListener(SUPPORT_EVENT_TRIGGER, handleEvent);
        };
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.has(SUPPORT_URL_PARAM)) {
            setOpen(true);
        }
    }, [location.search]);

    const handleClose = () => {
        setOpen(false);
        const params = new URLSearchParams(location.search);

        params.delete(SUPPORT_URL_PARAM);
        history.push(`${location.pathname}?${params.toString()}`);
    };

    return (
        <>
            <Helmet
                script={[
                    {
                        type: 'text/javascript',
                        src: 'https://app-cdn.clickup.com/assets/js/forms-embed/v1.js',
                    },
                ]}
            />
            <Modal size="md" isOpen={isOpen} cancelOnClick={handleClose} contentClassName={styles.content}>
                <iframe
                    src={formUrl}
                    onWheel=""
                    width="100%"
                    height={window.innerHeight * 0.9}
                    style={{
                        background: 'transparent',
                        border: 'none',
                    }}
                ></iframe>
            </Modal>
        </>
    );
}

SupportModal.propTypes = {};

export default SupportModal;
